<template>
	<div class="card">
		<cms-text
			class="card-title"
			:value="title"
			:props="{color: 'white', variant: 'medium-header'}"
		/>
		<div class="card-inner-wrapper">
			<slot/>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		title: String,
		content: String
	}
}
</script>
<style lang="scss" scoped>
.card {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	border: 1px solid $gray-200;
	padding: 3rem 2rem;
	@include media-breakpoint-up(md) {
		padding: 0 2rem 3rem;
	}
	@include media-breakpoint-up(lg) {
	}
}
</style>

<template>
	<section class="section" v-background="value.section_background.path">
		<base-container>
			<base-row>
				<div class="cards-wrapper">
					<base-card
						v-for="(card, i) in this.value.cards" :key="card.card_title"
						:class="{last: i === value.cards.length - 1}"
						:title="`${prefix}.cards.${i}.card_title`"
					>
						<cms-text
							:value="`${prefix}.cards.${i}.card_content`"
							:props="{color: 'white', tag:'div', variant: 'paragraph', tag: 'h3'}"
						/>
					</base-card>
				</div>
			</base-row>
		</base-container>
	</section>
</template>
<script>
import BaseCard from '../../components/molecules/cards/BaseCard/'

export default {
	components: {
		BaseCard
	},
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>
.section {
	padding: 5rem 0;
	margin-top: 2rem;
	background-color: gray;
	background-size: cover;
	position: relative;

	&::after{
		content: '';
		height: 100%;
		width: 100%;
		background-color: rgba(0,0,0,0.25);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
	}

	.cards-wrapper {
		width: 100%;
		padding: 0 1.5rem;
		display: flex;
		flex-direction: column;
		z-index: 2;
		@include media-breakpoint-up(lg) {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-gap: 2rem;
		}
	}
	& .card {
		width: auto;
		cursor: default;
		@include media-breakpoint-up(xxl) {
			width: 450px;
		}
	}
	& .card:not(.last) {
		margin-bottom: 3rem;
	}
	@include media-breakpoint-up(lg) {
		& .card:not(.last) {
			margin-bottom: 0;
		}
		padding: 8rem 0;
		margin-top: 4rem;
	}
}
</style>
